export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "gsinterns"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://yy2uoea196.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_141Ho5fdo",
    APP_CLIENT_ID: "5edngq75us6krp6pdda44c73g0",
    IDENTITY_POOL_ID: "us-east-1:447b4ce3-ba9d-42b3-8928-db906a6caedb"
  }
};
