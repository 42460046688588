import React from 'react';

import axios from 'axios';
import cheerio from 'cheerio';


export default class PersonList extends React.Component {
  state = {
    persons: []
  }

  componentDidMount() {
    axios.get(`https://www.investing.com/commodities/real-time-futures`)
      .then(response => {
        if(response.status === 200)
          {
            const html = response.data;
            const $ = cheerio.load(html);
            let data = [];
            $("#cross_rate_1 > tbody > tr > td").each((i, elem) => {
                // data.push({
                //   Month: $(elem).find('td#left noWrap').text()
                // })
                console.log($(elem).text());
            });
            // console.log(data);
          }
        }, (error) => console.log('err') );
  }

  render()
  {
    return (
      <ul>
        { }
      </ul>
    )
  }
}
