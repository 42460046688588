import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import PersonList from "./PersonList";
import JupViewer from "./JupViewer";
export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login/>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup/>
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/notes/new">
        <NewNote />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notes/:id">
        <Notes />
      </AuthenticatedRoute>
      <Route>
      <JupViewer
                title="Lower 48 Nat Gas S&D"
                subtitle="Using Multivariate Linear Regression w/ ANN"
                // coverImg="https://notionpress.com/blog/wp-content/uploads/2018/06/Cover-design.png"
                // file={ipynb}
                file="https://raw.githubusercontent.com/ageron/handson-ml/master/01_the_machine_learning_landscape.ipynb"
                // file="https://raw.githubusercontent.com/fastai/course-v3/master/nbs/dl1/00_notebook_tutorial.ipynb"
              />
      </Route>
    </Switch>
  );
}
