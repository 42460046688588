import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import Routes from "./Routes";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./libs/errorLib";
import "./App.css";

function App()
{
  const history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

async function onLoad()
{
  try {
    await Auth.currentSession();
    userHasAuthenticated(true);
  }
  catch(e) {
    if (e !== 'No current user') {
      onError(e);
    }
  }
  setIsAuthenticating(false);
}
async function handleLogout() {
  await Auth.signOut();

  userHasAuthenticated(false);

  history.push("/login");
}
  return (
    !isAuthenticating &&
    <div className="App container">
      <Navbar bg="dark" variant="dark" fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">GS COMMOD</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {isAuthenticated
              ? <NavItem onClick={handleLogout}>Logout</NavItem>
              : <>
                  <LinkContainer to="/signup">
                    <NavItem>Signup</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider
        value={{ isAuthenticated, userHasAuthenticated }}
      >
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;
